import ApolloClient from 'apollo-client';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import {
  DELETE_NOTICE,
  MODIFIED_NOTICE,
  REGISTER_NOTICE,
} from '@/domain/notice/queries/mutation';
import { Notice, NoticeParam, Result } from '@/domain/notice/model/Notice';

export class NoticeService {
  private readonly apollo: ApolloClient<NormalizedCacheObject>;

  constructor(apollo: ApolloClient<NormalizedCacheObject>) {
    this.apollo = apollo;
  }

  async saveNotice(param: NoticeParam): Promise<Notice> {
    const response = await this.apollo.mutate({
      mutation: REGISTER_NOTICE,
      variables: {
        param: {
          title: param.title,
          content: param.content,
          type: param.type,
          forHostOnly: param.forHostOnly,
        },
      },
    });

    return response.data.registerNotice;
  }

  async deleteNotice(id: number): Promise<Result> {
    const response = await this.apollo.mutate({
      mutation: DELETE_NOTICE,
      variables: { id: id },
    });

    return response.data.deleteNotice;
  }

  async updateNotice(param: NoticeParam): Promise<Notice> {
    console.log(param);
    const response = await this.apollo.mutate({
      mutation: MODIFIED_NOTICE,
      variables: {
        param: {
          id: param.id,
          title: param.title,
          content: param.content,
          type: param.type,
          forHostOnly: param.forHostOnly,
        },
      },
    });

    return response.data.updateNotice;
  }
}
