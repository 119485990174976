






































































import Vue from 'vue';
import Spinner from '@/components/Spinner.vue';
import FroalaEditor from '@/components/FroalaEditor.vue';
import { Option, Select } from 'element-ui';
import {
  HOST,
  Notice,
  NoticeConnection,
  NoticeParam,
  NoticeTypeInfo,
  SERVICE,
} from '@/domain/notice/model/Notice';
import { NOTICE, NOTICE_CATEGORY_LIST } from '@/domain/notice/queries/query';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { NoticeService } from '@/domain/notice/service/NoticeService';
import { apolloClient } from '@/apolloClient';

const noticeService = new NoticeService(apolloClient);
export default Vue.extend({
  name: 'NoticeDetailContainer',
  components: {
    FroalaEditor,
    [Select.name]: Select,
    [Option.name]: Option,
    Spinner,
  },
  props: {
    id: String,
    forHostOnly: String,
  },
  data(): {
    categories: NoticeTypeInfo[];
    filter: { id: string; forHostOnly: boolean };
    notice: Notice;
    loading: boolean;
  } {
    return {
      categories: [],
      filter: { id: '', forHostOnly: false },
      notice: {
        title: '',
        content: '',
        type: '',
      } as Notice,
      loading: true,
    };
  },
  created() {
    //id값이 없을 경우는 query skip
    if (!this.id) {
      this.$apollo.queries.notices.skip = true;
      this.$data.loading = false;
    }

    this.$data.filter.id = this.id;
    this.$data.filter.forHostOnly = this.forHostOnly
      ? JSON.parse(this.forHostOnly)
      : true;
  },
  methods: {
    async saveNotice(): Promise<void | boolean> {
      if (
        this.$data.notice.content === '' ||
        this.$data.notice.title === '' ||
        !this.$data.notice.type
      ) {
        this.$modal.show({
          title: '공지사항 등록 오류!',
          type: 'warning',
          message: '필수 값을 등록해주세요.',
        });

        return false;
      }

      const param: NoticeParam = {
        title: this.$data.notice.title,
        content: this.$data.notice.content,
        type: this.$data.notice.type,
        forHostOnly: this.$data.filter.forHostOnly,
      };

      this.$modal.show(
        {
          title: '공지사항 저장!',
          type: 'info',
          message: '공지사항을 저장하시겠습니까?',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            await noticeService.saveNotice(param);

            this.$modal.show(
              {
                title: '공지사항 등록 완료',
                type: 'success',
                message: '공지사항 등록이 완료되었습니다.',
              },
              () => {
                (this as any).redirectPage();
              }
            );
          } catch (err) {
            this.$modal.show({
              title: '공지사항 저장 실패!',
              type: 'warning',
            });
          }
        },
        () => {
          return false;
        }
      );
    },
    async updateNotice(): Promise<void | boolean> {
      const param: NoticeParam = {
        id: this.$data.notice.id,
        title: this.$data.notice.title,
        content: this.$data.notice.content,
        type: this.$data.notice.type,
        forHostOnly: this.$data.filter.forHostOnly,
      };

      this.$modal.show(
        {
          title: '공지사항 수정!',
          type: 'info',
          message: '공지사항을 수정하시 겠습니까?',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            await noticeService.updateNotice(param);

            this.$modal.show(
              {
                title: '공지사항 수정 완료',
                type: 'success',
                message: '공지사항 수정이 완료되었습니다.',
              },
              () => {
                (this as any).redirectPage();
              }
            );
          } catch (err) {
            console.log(err);
            this.$modal.show({
              title: '공지사항 수정 실패!',
              type: 'warning',
            });
          }
        },
        () => {
          return false;
        }
      );
    },
    async deleteNotice(id: number): Promise<void> {
      this.$modal.show(
        {
          title: '공지사항 삭제!',
          type: 'info',
          message: '공지사항을 삭제 하시겠습니까?',
          showCancelButton: true,
          cancelText: '취소',
        },
        async () => {
          try {
            await noticeService.deleteNotice(id);

            this.$modal.show(
              {
                title: '삭제 완료!',
                type: 'success',
                message: '삭제가 완료 되었습니다.',
              },
              () => {
                (this as any).redirectPage();
              }
            );
          } catch (err) {
            this.$modal.show({
              title: '삭제 실패!',
              type: 'warning',
              message: '삭제 실패했습니다.',
            });
          }
        }
      );
    },
    redirectPage(): void {
      console.log(this);
      const typeName = this.$data.filter.forHostOnly
        ? HOST.toLowerCase()
        : SERVICE.toLowerCase();
      this.$router.push('/notice/list/' + typeName);
    },
  },
  apollo: {
    notices: {
      query: NOTICE,
      variables() {
        return {
          filter: this.$data.filter,
          page: 1,
          size: 1,
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(
        result: ApolloQueryResult<{ board: { notices: NoticeConnection } }>
      ) {
        this.$data.notice = result.data.board.notices.edges[0].node;
        this.$data.loading = false;
      },
      update: data => {
        return data.board.notices.edges[0].node;
      },
    },
    noticeTypes: {
      query: NOTICE_CATEGORY_LIST,
      variables() {
        return {
          param: { forHostOnly: this.$data.filter.forHostOnly },
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      result(result: ApolloQueryResult<{ board: { noticeTypes: any[] } }>) {
        this.$data.categories = result.data.board.noticeTypes;
      },
      update: data => {
        return data.board.noticeTypes;
      },
    },
  },
});
