import gql from 'graphql-tag';

export const REGISTER_NOTICE = gql`
  mutation ($param: NoticeParam!) {
    createNotice(param: $param) {
      id
      title
      content
      type
      createdAt
    }
  }
`;

export const MODIFIED_NOTICE = gql`
  mutation ($param: NoticeParam!) {
    updateNotice(param: $param) {
      id
      title
      content
      type
      createdAt
    }
  }
`;

export const DELETE_NOTICE = gql`
  mutation ($id: ID!) {
    deleteNotice(id: $id) {
      success
      message
    }
  }
`;
